$(document).ready(function () {
	
	// Redirect to the first subpage if we're at the root
	if ( $('.navigation a').first().parent('li').hasClass('current') ) {
		window.location = $('.navigation a').eq(1).attr('href');
	}
	
	// Hide the root nav item
	$('.navigation a').first().hide();

	// Scroll the nav down to ensure the current page is visible
	$currentLink = $('.navigation .current');
	if (!isVisible($currentLink[0], $('.navigation')[0])) {
		$('.navigation').animate({
			scrollTop: $currentLink.offset().top - $('.navigation').offset().top + $('.navigation').scrollTop()
		}, 0);
	}

	// Standard content image popups
	$('figure > a').colorbox({
		maxWidth: '90%',
		maxHeight: '90%'
	});
	
});

function isVisible(ele, container) {
	var eleTop = ele.offsetTop;
	var eleBottom = eleTop + ele.clientHeight;

	var containerTop = container.scrollTop;
	var containerBottom = containerTop + container.clientHeight;

	// The element is fully visible in the container
	return (
			(eleTop >= containerTop && eleBottom <= containerBottom) ||
			// Some part of the element is visible in the container
			(eleTop < containerTop && containerTop < eleBottom) ||
			(eleTop < containerBottom && containerBottom < eleBottom)
	);
};
